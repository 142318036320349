<template>
  <div>
    <div style="text-align:center;">
      <h1>Register-TODO:</h1>
    </div>
    <v-form lazy-validation ref="form" v-model="valid">
      <v-text-field :counter="10" :rules="nameRules" label="Name" required v-model="name"></v-text-field>

      <v-text-field :rules="emailRules" label="E-mail" required v-model="email"></v-text-field>

      <v-select
              :items="items"
              :rules="[v => !!v || 'Item is required']"
              label="Item"
              required
              v-model="select"
      ></v-select>

      <v-checkbox
              :rules="[v => !!v || 'You must agree to continue!']"
              label="Do you agree?"
              required
              v-model="checkbox"
      ></v-checkbox>

      <v-btn :disabled="!valid" @click="validate" color="success">Validate</v-btn>

      <v-btn @click="reset" color="error">Reset Form</v-btn>

      <v-btn @click="resetValidation" color="warning">Reset Validation</v-btn>
    </v-form>
  </div>
</template>

<script>
  export default {
    data: () => ({
      valid: true,
      name: "",
      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length <= 10) || "Name must be less than 10 characters"
      ],
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false
    }),

    methods: {
      validate() {
        if (this.$refs.form.validate()) {
          this.snackbar = true;
        }
      },
      reset() {
        this.$refs.form.reset();
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      }
    }
  };
</script>
